$circle-size: 400px;
$small-circle-size: 300px;

.footer-outer {
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  .footer-clip {
    width: 150%;
    height: 600px;
    background: #000;
    clip-path: ellipse(50% 40% at 50% 100%);
    margin: 0 0 -100px 0;
  }

  .footer {
    background: #000;
    height: 100%;
    width: 100%;
    z-index: 100;

    .footer-content {
      max-width: 1400px;
      margin: 0 auto;
      width: 100%;
      color: #fff;
      padding: 0 50px 0px 50px;
      display: flex;
      justify-content: center;
      align-items: stretch;
    }

    &-left {
      flex: 1;
      h2 {
        color: #fff;
        font-weight: 700;
        font-size: 60px;
      }

    }

    .images-header{
      text-align: center;
      margin: 0 0 20px 0;
      font-size: 28px;
    }

    .images{
      max-width: 1100px;
      align-items: center;
      display: flex;
      justify-content: space-between;
      gap: 40px;
      margin: 0 auto 80px auto;
      flex-wrap: wrap;

      .img{
        flex: 1;
        width: 100%;
        min-width: 100px;
        max-width: 160px;
        margin: 0 auto;
        img{
          width: 100%;
        }
      }
    }

    .m-left {
      margin: 0 0 0 40px;
      display: flex;
      flex-direction: column;
      max-width: 1400px;
      margin: 0 auto;
      width: 100%;
      color: #fff;
      padding: 0 50px 60px 50px;

      .footer-btn {
        font-size: 16px;
        font-weight: 600;
        margin: 45px 0 80px 0;
        max-width: max-content;
      }

      .row {
        display: flex;
        align-items: center;
        .text {
          margin: 0 0 0 15px;
          font-weight: 400;
          font-size: 16px;
          color: #fff;
          text-decoration: none;
        }
      }

      .location {
        margin: 0 0 10px 0;
      }

      .whats{
        margin: 10px 0 0 0;
      }

      .space-between {
        justify-content: space-between;
        gap: 15px;
        flex-wrap: wrap;
      }

      .social-media {
        display: flex;
        margin: 45px 0 0 0;
        align-items: center;

        p {
          margin: 0 15px 0 0;
          font-weight: 400;
          font-size: 14px;
          color: #ffffffb3;
        }

        &-links {
          display: flex;
          align-items: center;
          gap: 24px;

          a {
            text-decoration: none;
            color: #fff;
            width: 24px;
            height: 24px;

            svg {
              width: 100%;
              height: 100%;
              fill: #fff;
            }

            img{
              width: 100%;
              height: 100%;
              color: #fff;
            }
          }
        }
      }

      .payments{
        display: flex;
        gap: 20px;
        align-items: center;
        margin: 10px 0 0 0;

        .payment{
          max-width: 150px;

          img, svg{
            width: 100%;
          }
        }
      }

      .pages{
        display: flex;
        gap: 30px;
        margin: 50px 0 0 0;

        a{
          color: #ffffffb3;
          text-decoration: none;
        }
      }
    }

    &-right {
      display: flex;
      flex-direction: column;
      width: 100%;
      flex: 1;
      align-items: flex-end;
      justify-content: space-between;

      .footer-image {
        position: relative;
        max-width: 667px;
        height: 667px;
        width: 100%;
        margin: -400px 0 0 0;

        .mask {
          z-index: 1;
          position: absolute;
          border-radius: 50%;
          width: 100%;
          height: 100%;
          overflow: hidden;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }

        .mask-1 {
          max-width: $circle-size + 133px;
          max-height: $circle-size + 133px;
          background: #000;
          opacity: 0.05;
        }

        .mask-2 {
          max-width: $circle-size + 62px;
          max-height: $circle-size + 62px;
          background: #000;
          opacity: 0.1;
        }

        .mask-3 {
          max-width: $circle-size;
          max-height: $circle-size;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    .footer-logo {
      justify-self: end;
      max-width: 100px;
      width: 100%;
      display: flex;

      img {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .footer-outer {
    .footer {
      .footer-right {
        .footer-image {
          .mask-1 {
            max-width: $small-circle-size + 133px;
            max-height: $small-circle-size + 133px;
          }

          .mask-2 {
            max-width: $small-circle-size + 62px;
            max-height: $small-circle-size + 62px;
          }

          .mask-3 {
            max-width: $small-circle-size;
            max-height: $small-circle-size;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 850px) {
  .footer-outer {
    overflow: hidden;
    .footer-clip {
      clip-path: ellipse(50% 25% at 50% 100%);
    }
    .footer {
      .footer-content {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        flex: unset;
      }
      .footer-left {
        justify-self: flex-start;
        text-align: left;
        margin: 30px 0 0 0;
        h2 {
          color: #fff;
          font-weight: 700;
          font-size: 36px;
        }

        .m-left {
          margin: 0;
          .footer-btn {
            font-size: 16px;
            font-weight: 600;
            margin: 25px 0 40px 0;
            max-width: max-content;
          }
        }
      }
      .footer-right {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .footer-outer {
    .footer {
      .footer-content {
        padding: 0 20px 50px 20px;
      }

      .footer-left {
        h2 {
          font-size: 24px;
        }

        .m-left {
          margin: 0;

          .row {
            .text {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
