.footer-outer {
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.footer-outer .footer-clip {
  width: 150%;
  height: 600px;
  background: #000;
  -webkit-clip-path: ellipse(50% 40% at 50% 100%);
          clip-path: ellipse(50% 40% at 50% 100%);
  margin: 0 0 -100px 0;
}
.footer-outer .footer {
  background: #000;
  height: 100%;
  width: 100%;
  z-index: 100;
}
.footer-outer .footer .footer-content {
  max-width: 1400px;
  margin: 0 auto;
  width: 100%;
  color: #fff;
  padding: 0 50px 0px 50px;
  display: flex;
  justify-content: center;
  align-items: stretch;
}
.footer-outer .footer-left {
  flex: 1;
}
.footer-outer .footer-left h2 {
  color: #fff;
  font-weight: 700;
  font-size: 60px;
}
.footer-outer .footer .images-header {
  text-align: center;
  margin: 0 0 20px 0;
  font-size: 28px;
}
.footer-outer .footer .images {
  max-width: 1100px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  gap: 40px;
  margin: 0 auto 80px auto;
  flex-wrap: wrap;
}
.footer-outer .footer .images .img {
  flex: 1;
  width: 100%;
  min-width: 100px;
  max-width: 160px;
  margin: 0 auto;
}
.footer-outer .footer .images .img img {
  width: 100%;
}
.footer-outer .footer .m-left {
  margin: 0 0 0 40px;
  display: flex;
  flex-direction: column;
  max-width: 1400px;
  margin: 0 auto;
  width: 100%;
  color: #fff;
  padding: 0 50px 60px 50px;
}
.footer-outer .footer .m-left .footer-btn {
  font-size: 16px;
  font-weight: 600;
  margin: 45px 0 80px 0;
  max-width: -moz-max-content;
  max-width: max-content;
}
.footer-outer .footer .m-left .row {
  display: flex;
  align-items: center;
}
.footer-outer .footer .m-left .row .text {
  margin: 0 0 0 15px;
  font-weight: 400;
  font-size: 16px;
  color: #fff;
  text-decoration: none;
}
.footer-outer .footer .m-left .location {
  margin: 0 0 10px 0;
}
.footer-outer .footer .m-left .whats {
  margin: 10px 0 0 0;
}
.footer-outer .footer .m-left .space-between {
  justify-content: space-between;
  gap: 15px;
  flex-wrap: wrap;
}
.footer-outer .footer .m-left .social-media {
  display: flex;
  margin: 45px 0 0 0;
  align-items: center;
}
.footer-outer .footer .m-left .social-media p {
  margin: 0 15px 0 0;
  font-weight: 400;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.7019607843);
}
.footer-outer .footer .m-left .social-media-links {
  display: flex;
  align-items: center;
  gap: 24px;
}
.footer-outer .footer .m-left .social-media-links a {
  text-decoration: none;
  color: #fff;
  width: 24px;
  height: 24px;
}
.footer-outer .footer .m-left .social-media-links a svg {
  width: 100%;
  height: 100%;
  fill: #fff;
}
.footer-outer .footer .m-left .social-media-links a img {
  width: 100%;
  height: 100%;
  color: #fff;
}
.footer-outer .footer .m-left .payments {
  display: flex;
  gap: 20px;
  align-items: center;
  margin: 10px 0 0 0;
}
.footer-outer .footer .m-left .payments .payment {
  max-width: 150px;
}
.footer-outer .footer .m-left .payments .payment img, .footer-outer .footer .m-left .payments .payment svg {
  width: 100%;
}
.footer-outer .footer .m-left .pages {
  display: flex;
  gap: 30px;
  margin: 50px 0 0 0;
}
.footer-outer .footer .m-left .pages a {
  color: rgba(255, 255, 255, 0.7019607843);
  text-decoration: none;
}
.footer-outer .footer-right {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  align-items: flex-end;
  justify-content: space-between;
}
.footer-outer .footer-right .footer-image {
  position: relative;
  max-width: 667px;
  height: 667px;
  width: 100%;
  margin: -400px 0 0 0;
}
.footer-outer .footer-right .footer-image .mask {
  z-index: 1;
  position: absolute;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  overflow: hidden;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.footer-outer .footer-right .footer-image .mask-1 {
  max-width: 533px;
  max-height: 533px;
  background: #000;
  opacity: 0.05;
}
.footer-outer .footer-right .footer-image .mask-2 {
  max-width: 462px;
  max-height: 462px;
  background: #000;
  opacity: 0.1;
}
.footer-outer .footer-right .footer-image .mask-3 {
  max-width: 400px;
  max-height: 400px;
}
.footer-outer .footer-right .footer-image .mask-3 img {
  width: 100%;
  height: 100%;
}
.footer-outer .footer .footer-logo {
  justify-self: end;
  max-width: 100px;
  width: 100%;
  display: flex;
}
.footer-outer .footer .footer-logo img {
  width: 100%;
}

@media screen and (max-width: 1024px) {
  .footer-outer .footer .footer-right .footer-image .mask-1 {
    max-width: 433px;
    max-height: 433px;
  }
  .footer-outer .footer .footer-right .footer-image .mask-2 {
    max-width: 362px;
    max-height: 362px;
  }
  .footer-outer .footer .footer-right .footer-image .mask-3 {
    max-width: 300px;
    max-height: 300px;
  }
}
@media screen and (max-width: 850px) {
  .footer-outer {
    overflow: hidden;
  }
  .footer-outer .footer-clip {
    -webkit-clip-path: ellipse(50% 25% at 50% 100%);
            clip-path: ellipse(50% 25% at 50% 100%);
  }
  .footer-outer .footer .footer-content {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex: unset;
  }
  .footer-outer .footer .footer-left {
    justify-self: flex-start;
    text-align: left;
    margin: 30px 0 0 0;
  }
  .footer-outer .footer .footer-left h2 {
    color: #fff;
    font-weight: 700;
    font-size: 36px;
  }
  .footer-outer .footer .footer-left .m-left {
    margin: 0;
  }
  .footer-outer .footer .footer-left .m-left .footer-btn {
    font-size: 16px;
    font-weight: 600;
    margin: 25px 0 40px 0;
    max-width: -moz-max-content;
    max-width: max-content;
  }
  .footer-outer .footer .footer-right {
    display: none;
  }
}
@media screen and (max-width: 500px) {
  .footer-outer .footer .footer-content {
    padding: 0 20px 50px 20px;
  }
  .footer-outer .footer .footer-left h2 {
    font-size: 24px;
  }
  .footer-outer .footer .footer-left .m-left {
    margin: 0;
  }
  .footer-outer .footer .footer-left .m-left .row .text {
    font-size: 14px;
  }
}/*# sourceMappingURL=Footer.css.map */