@import url("https://fonts.googleapis.com/css2?family=Golos+Text:wght@400;500;600;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;500;600;700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Golos Text", sans-serif;
}

body {
  background: #f5f5f5;
}

.btn {
  padding: 14px 55px;
  background: #ca9841;
  display: inline-block;
  font-size: 16px;
  color: #fff;
  text-decoration: none;
  font-weight: 600;
  font-family: "IBM Plex Sans", sans-serif;
  border-radius: 5px;
  cursor: pointer;
}
