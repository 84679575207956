.navbar-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.navbar-list a {
  color: #fff;
  margin: 0 30px;
  transition: all 0.1s ease-in-out;
}
.navbar-list .main {
  color: #ca9841;
}
.navbar-list a:hover {
  color: #ca9841;
}

.MuiSelect-standard {
  color: #ca9841 !important;
  margin: 4px 0 0 0 !important;
}

.MuiSelect-icon {
  color: #ca9841 !important;
}

.navbar-background {
  position: sticky;
  top: 0;
  width: 100%;
  background: #000;
  padding: 5px 50px;
  z-index: 1200;
}
.navbar-background .navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1300px;
  padding: 20px 50px;
  margin: 0 auto;
  position: relative;
}
.navbar-background .navbar .logo {
  width: 120px;
  position: absolute;
  top: 50%;
  transform: translateY(-20%);
}
.navbar-background .navbar .logo img {
  width: 100%;
}
.navbar-background .navbar .logo .show-sm {
  display: none;
}
.navbar-background .navbar li {
  list-style: none;
}
.navbar-background .navbar a {
  text-decoration: none;
  cursor: pointer;
  font-weight: 500;
  font-size: 15px;
}
.navbar-background .navbar ul {
  display: flex;
}
.navbar-background .navbar .navbar-hamburger {
  display: none;
  color: #fff;
  font-size: 30px;
  cursor: pointer;
}

.mobile-navbar {
  background: #000;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 15px 30px;
  align-items: flex-start;
  text-align: start;
  min-width: 200px;
}
.mobile-navbar img {
  margin: 0 0 0 -20px;
}
.mobile-navbar .navbar-list {
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
}
.mobile-navbar .navbar-list a {
  text-decoration: none;
  margin: 0;
}
.mobile-navbar .navbar-language {
  margin: 40px 0 0 0;
}

@media screen and (max-width: 800px) {
  .navbar-background {
    padding: 5px 0;
  }
  .navbar-background .navbar {
    padding: 0 35px;
  }
}
@media screen and (max-width: 1000px) {
  .navbar-background .navbar {
    padding: 5px 50px;
  }
  .navbar-background .navbar .logo {
    position: static;
    transform: translateY(0);
  }
  .navbar-background .navbar .logo .show-sm {
    display: block;
  }
  .navbar-background .navbar .logo .hide-sm {
    display: none;
  }
  .navbar-background .navbar .navbar-hamburger {
    display: block;
  }
  .navbar-background .navbar .navbar-list {
    display: none;
  }
  .navbar-background .navbar .navbar-language {
    display: none;
  }
}/*# sourceMappingURL=Navbar.css.map */