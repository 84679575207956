@use "../../pages/HomePage/Homepage.scss" as main;
$main-color: main.$main-color;
$secondary-color: main.$secondary-color;
$text-color: main.$text-color;
$ibm: main.$ibm;
$golos: main.$golos;

.navbar-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  a {
    color: #fff;
    margin: 0 30px;
    transition: all 0.1s ease-in-out;
  }

  .main {
    color: $main-color;
  }

  a:hover {
    color: $main-color;
  }
}

.MuiSelect-standard {
  color: $main-color !important;
  margin: 4px 0 0 0 !important;
}

.MuiSelect-icon {
  color: $main-color !important;
}

.navbar-background {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  width: 100%;
  background: #000;
  padding: 5px 50px;
  z-index: 1200;

  .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1300px;
    padding: 20px 50px;
    margin: 0 auto;
    position: relative;

    .logo {
      width: 120px;
      position: absolute;
      top: 50%;
      transform: translateY(-20%);

      img {
        width: 100%;
      }

      .show-sm {
        display: none;
      }
    }

    li {
      list-style: none;
    }

    a {
      text-decoration: none;
      cursor: pointer;
      font-weight: 500;
      font-size: 15px;
    }

    ul {
      display: flex;
    }

    .navbar-hamburger {
      display: none;
      color: #fff;
      font-size: 30px;
      cursor: pointer;
    }
  }
}

.mobile-navbar {
  background: #000;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 15px 30px;
  align-items: flex-start;
  text-align: start;
  min-width: 200px;

  img {
    margin: 0 0 0 -20px;
  }

  .navbar-list {
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;

    a {
      text-decoration: none;
      margin: 0;
    }
  }

  .navbar-language {
    margin: 40px 0 0 0;
  }
}

@media screen and (max-width: 800px) {
  .navbar-background {
    padding: 5px 0;
    .navbar {
      padding: 0 35px;
    }
  }
}

@media screen and (max-width: 1000px) {
  .navbar-background {
    .navbar {
      padding: 5px 50px;

      .logo {
        position: static;
        transform: translateY(0);
        
        .show-sm {
          display: block;
        }

        .hide-sm {
          display: none;
        }
      }

      .navbar-hamburger {
        display: block;
      }

      .navbar-list {
        display: none;
      }

      .navbar-language {
        display: none;
      }
    }
  }
}
